import { GetStaticProps } from 'next'
import React, { ReactElement } from 'react'

import { Button } from 'ui/button'

import MainLayout from 'components/layout/main-layout'

import useTranslation from 'lib/hooks/useTranslation'

import { buildPath } from 'lib/utils'
import { serverSideTranslations } from 'lib/utils/server'

import { HOME_ROUTE } from 'lib/constants/routes'

import NotFoundImg from 'brand-assets/illustrations/page-not-found.svg'

import s from './styles.module.scss'

const NotFound = () => {
  const { t, tp } = useTranslation()
  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.title}>{t('msg.pageNotFound')}</div>
        <NotFoundImg className={s.illustration} />
        <div className={s.description}>{tp('msg.whatYouAreLookingForIsntHere')}</div>
        <div className={s.buttonContainer}>
          <Button
            variant="primary"
            size="large"
            fluid
            onClick={() => {
              // Need a full page refresh here to trigger the getInitialProps in _app page.
              // Static pages doesnt call _app getInitialProps and this is required for getting the latest globalArgs.
              window.location.href = buildPath(HOME_ROUTE)
            }}
          >
            {t('action.back', { ns: 'common' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, 'ERROR_PAGE')),
    },
  }
}

NotFound.getLayout = function getLayout(page: ReactElement) {
  return (
    <MainLayout noFooter variant="static">
      <MainLayout.Content whiteBg>{page}</MainLayout.Content>
    </MainLayout>
  )
}
